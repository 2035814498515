import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { shallowEqual } from '@beewise/react-utils';
import { useDispatch, useSelector } from 'react-redux';
import { convertArea, getPlural, measureUnits } from 'utils/isIsraeliRanch';
import RanchDetails from 'components/reusables/RightPanel/RanchDetails';
import { getBeeAverage, getHiveCorrectionPercent } from 'data';
import { fetchUser } from '../../actions';
import { getFramesPerHiveByBhomeId, getRanchBroker } from '../../selectors';
import { calculateCurrentAverageBeeFramesPerHive, calculateHivesCorrection } from './utils';
import './RanchInfo.scss';

const getHardcodedCorrection = ({ totalBhomesHives, hiveCorrectionPercent }) => {
    const correctedHivesAmount = Math.round((hiveCorrectionPercent * totalBhomesHives) / 100);
    const percent = correctedHivesAmount && hiveCorrectionPercent;

    return `${correctedHivesAmount} (${percent}%)`;
};

const RanchInfo = ({ ranch, bhomesAmount, startDate, endDate, isHardcodedData }) => {
    const dispatch = useDispatch();
    const broker = useSelector(getRanchBroker, shallowEqual);
    const framesPerHiveByBhomeId = useSelector(getFramesPerHiveByBhomeId, shallowEqual);
    const regularBhomesAmount =
        ranch?.locations?.reduce(
            (total, location) => (location.bhomeId ? total : total + location.placedHivesAmount),
            0
        ) || 0;

    const currentAverageBeeFramesPerHive = isHardcodedData
        ? getBeeAverage(ranch.id)
        : calculateCurrentAverageBeeFramesPerHive(framesPerHiveByBhomeId);

    const totalBhomesHives = ranch?.locations?.reduce((acc, location) => {
        if (location.bhomeId) {
            acc += location.hivesAmount;
        }
        return acc;
    }, 0);

    const correction = isHardcodedData
        ? getHardcodedCorrection({ totalBhomesHives, hiveCorrectionPercent: getHiveCorrectionPercent(ranch.id) })
        : calculateHivesCorrection(framesPerHiveByBhomeId, ranch?.locations);

    useEffect(() => {
        if (!ranch?.createdBy) {
            return;
        }

        dispatch(fetchUser(ranch.createdBy));
    }, [dispatch, ranch]);

    if (!ranch) {
        return;
    }

    return (
        <div className="ranch-info">
            {!!bhomesAmount && (
                <div className="text bold">
                    {bhomesAmount} {getPlural('Beehome', bhomesAmount)}{' '}
                    {regularBhomesAmount
                        ? `| ${regularBhomesAmount} Standard
                    ${getPlural('hive', regularBhomesAmount)} `
                        : ''}
                    | {convertArea(ranch?.totalArea, ranch?.country).toFixed()} {measureUnits[ranch?.country].area}
                </div>
            )}
            <div className="text">
                Start date: <span className="bold">{dayjs(startDate).format('MM/DD/YY')}</span>
            </div>
            <div className="text">
                Removal date: <span className="bold">{dayjs(endDate).format('MM/DD/YY')}</span>
            </div>
            {!!broker && (
                <div className="contact">
                    <div className="text">
                        Contact for support: <span className="bold">{broker.username}</span> | Phone:{' '}
                        <span className="bold">+{broker.phone}</span> | Email:{' '}
                        <a href={`mailto:${broker.email}`} className="bold">
                            {broker.email}
                        </a>
                    </div>
                </div>
            )}
            {!!currentAverageBeeFramesPerHive && (
                <>
                    <div className="text bold">Ranch Stats:</div>
                    <p className="text">
                        Average bee frames per hive: <span className="bold">{currentAverageBeeFramesPerHive}</span>
                    </p>
                    <p className="text">
                        Hives being corrected: <span className="bold">{correction}</span>
                    </p>
                </>
            )}
            {!bhomesAmount && <RanchDetails ranch={ranch} />}
        </div>
    );
};

export default RanchInfo;

RanchInfo.propTypes = {
    ranch: PropTypes.shape({
        id: PropTypes.string,
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                bhomeId: PropTypes.number,
            })
        ),
        createdBy: PropTypes.string,
        totalArea: PropTypes.number,
        country: PropTypes.string,
    }),
    bhomesAmount: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    isHardcodedData: PropTypes.bool,
};
