import { sortBy } from 'lodash-es';
import {
    FETCH_RANCHES,
    FETCH_USER,
    FETCH_FRAMES_PER_HIVE,
    SELECT_CURRENT_RANCH_BY_ID,
    FETCH_RANCH_APPROVAL,
    SET_RANCH_APPROVAL,
    FETCH_BEE_ACTIVITIES_OVER_TIME,
    FETCH_HIVE_DEVELOPMENT,
    FETCH_RANCH_WITH_BLOCKS,
} from '../actionTypes';

const grower = (
    state = {
        ranches: [],
        content: {},
        ranchBroker: null,
        framesPerHiveByBhomeId: null,
        selectedRanch: null,
        selectedRanchApproval: null,
        beeActivitiesOverTime: [],
    },
    action
) => {
    switch (action.type) {
        case FETCH_RANCHES.success:
            return {
                ...state,
                ranches: sortBy(action.data.ranches, [o => o?.locations.filter(location => location.bhomeId).length]),
                selectedRanch: state.selectedRanch
                    ? action.data.ranches.find(item => item.id === state.selectedRanch.id)
                    : null,
            };
        case FETCH_USER.success:
            return {
                ...state,
                ranchBroker: action.data,
            };
        case FETCH_FRAMES_PER_HIVE.success:
            return {
                ...state,
                framesPerHiveByBhomeId: action.data,
            };
        case SELECT_CURRENT_RANCH_BY_ID:
            return {
                ...state,
                selectedRanch: state.ranches.find(ranch => ranch.id === action.id),
            };
        case FETCH_RANCH_WITH_BLOCKS.success:
            return {
                ...state,
                selectedRanch: action.data?.ranch,
                ranches: [action.data?.ranch],
            };
        case FETCH_RANCH_APPROVAL.success:
        case SET_RANCH_APPROVAL.success:
            return {
                ...state,
                selectedRanchApproval: action.data || { ...state.selectedRanchApproval },
            };
        case FETCH_BEE_ACTIVITIES_OVER_TIME.success:
            return {
                ...state,
                beeActivitiesOverTime: action.data,
            };

        case FETCH_HIVE_DEVELOPMENT.success:
            return {
                ...state,
                content: action.data || { ...state.content },
            };
        default:
            return state;
    }
};

export default grower;
