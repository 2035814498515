import constants from 'appConstants';

const {
    REPORTS: { CORRECTION_FACTOR },
} = constants;

export const EXTRA_FRAMES = 2;

export const calculateCurrentAverageBeeFramesPerHive = framesData => {
    if (!framesData || !Object.keys(framesData)?.length) {
        return 0;
    }
    const numbers = Object.values(framesData).flat();
    if (!numbers.length) return 0;
    const sum = numbers.reduce((acc, val) => acc + val, 0);
    return ((sum / numbers.length) * CORRECTION_FACTOR + EXTRA_FRAMES).toFixed(2);
};

export const calculateHivesCorrection = (framesData, locations) => {
    if (!framesData || !Object.keys(framesData)?.length) {
        return 0;
    }
    const beeFramesAmountAgg = Object.values(framesData).flat();
    const weakFrames = beeFramesAmountAgg?.filter(beeFrames => beeFrames * CORRECTION_FACTOR < 6);
    if (!weakFrames.length) {
        return 0;
    }
    const weakFramesRatio = weakFrames.length / beeFramesAmountAgg.length;
    const totalHivesPerBhomeDrops = locations.reduce(
        // eslint-disable-next-line no-return-assign
        (acc, location) => (location.bhomeId ? (acc += location.hivesAmount) : acc),
        0
    );
    const correctedTotalAmount = (totalHivesPerBhomeDrops * weakFramesRatio).toFixed();
    const weakFramesPercent = (weakFramesRatio * 100).toFixed();
    return `${correctedTotalAmount} (${weakFramesPercent}%)`;
};
