import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { SelectField } from '@beewise/select-field';
import Button from '@beewise/button';
import { useDispatch } from 'react-redux';
import { deploymentData } from 'data';
import RanchInfo from '../Ranchlnfo';
import SeasonProgressMeter from '../reports/SeasonProgressMeter';
import BeeActivity from '../reports/BeeActivity';
import FramesPerHive from '../reports/FramesPerHive';
import './GeneralInfoWithReports.scss';
// import NumberOfSavedBees from '../reports/NumberOfSavedBees';
import { selectCurrentRanchById } from '../../actions';
import HiveDevelopment from '../reports/HiveDevelopment';

// for now we set fixed start date and end dates cuz we don’t know the brood levels (Feb 5 for us, Feb 15 for il)
const getPollinationSeasonDates = (country, id) => {
    const startDate = deploymentData[id]?.deploymentDate || '2024-02-15';
    const endDate = deploymentData[id]?.removalDate || '2024-04-15';
    return { startDate, endDate };
};

const GeneralInfoWithReports = ({ ranches = [], selectedRanch, exportImage }) => {
    const isHardcodedData = selectedRanch?.showPotential;
    const dispatch = useDispatch();
    const ranchOptions = ranches?.map(ranch => ({
        label: ranch.name,
        value: ranch.id,
    }));
    const bhomesAmount = selectedRanch?.locations?.reduce((acc, location) => acc + (location.bhomeId ? 1 : 0), 0) ?? 0;
    const { startDate, endDate } = getPollinationSeasonDates(selectedRanch?.country, selectedRanch?.id);

    const onRanchSelection = useCallback(
        value => {
            const selectedRanch = ranches.find(ranch => ranch.id === value);
            dispatch(selectCurrentRanchById(selectedRanch.id));
        },
        [dispatch, ranches]
    );

    return (
        !!ranches?.length && (
            <div className="general-info-with-reports">
                <div className="ranch-header">
                    <div className="nav-section">
                        {ranchOptions.length === 1 && selectedRanch ? (
                            <h3 className="ranch-header-text">{selectedRanch.name}</h3>
                        ) : (
                            <SelectField
                                className="search-select-field"
                                name="ranch"
                                options={ranchOptions}
                                value={selectedRanch?.id}
                                onChange={onRanchSelection}
                            />
                        )}
                        <Button
                            shadow
                            onClick={exportImage}
                            className="primary-blue remove-from-jpeg"
                            iconType="arrow-down-with-line-on-top"
                            iconSide="left"
                        >
                            download
                        </Button>
                    </div>
                    {/* {!!selectedRanch?.id && !!bhomesAmount && ( */}
                    {/*    <NumberOfSavedBees startDate={startDate} ranch={selectedRanch} bhomesAmount={bhomesAmount} /> */}
                    {/* )} */}
                </div>
                <RanchInfo
                    ranch={selectedRanch}
                    bhomesAmount={bhomesAmount}
                    startDate={startDate}
                    endDate={endDate}
                    isHardcodedData={isHardcodedData}
                />
                {!!selectedRanch?.id && !!bhomesAmount && (
                    <>
                        <div className="ranch-reports">
                            <SeasonProgressMeter startDate={startDate} endDate={endDate} />
                            <FramesPerHive ranchId={selectedRanch.id} isHardcodedData={isHardcodedData} />
                        </div>
                        <BeeActivity
                            isHardcodedData={isHardcodedData}
                            ranchId={selectedRanch.id}
                            startDate={dayjs(startDate).format('YYYY-MM-DD')}
                            endDate={dayjs().subtract(1, 'day').format('YYYY-MM-DD')}
                        />
                        <HiveDevelopment ranch={selectedRanch} isHardcodedData={isHardcodedData} />
                    </>
                )}
            </div>
        )
    );
};

export default memo(GeneralInfoWithReports);

GeneralInfoWithReports.propTypes = {
    ranches: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        })
    ),
    selectedRanch: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                bhomeId: PropTypes.number,
            })
        ),
        crop: PropTypes.string,
        country: PropTypes.string,
        showPotential: PropTypes.bool,
    }),
    exportImage: PropTypes.func.isRequired,
};
