import { DEFAULT_QUALITY_OF_FRAMES } from 'components/constants';
import dayjs from 'dayjs';

const sortByDate = data => data.sort((a, b) => dayjs.utc(a.date).valueOf() - dayjs.utc(b.date).valueOf());

const calculateCells = (honeyFramesPerHive, broodFramesPerHive) => {
    const honey = Number(honeyFramesPerHive) || 0;
    const brood = Number(broodFramesPerHive) || 0;

    return honey + brood;
};

const prepareContent = item => {
    const sortedContent = sortByDate(item.aggr);

    return sortedContent.map(contentItem => {
        const numCells = calculateCells(contentItem.honeyFramesPerHive, contentItem.broodFramesPerHive);

        if (numCells && item.bhomeId) {
            return {
                date: contentItem.date,
                numCells: Math.round(numCells / (item.placedHivesAmount || 1)),
            };
        }

        return { date: contentItem.date, numCells };
    });
};

export const prepareContentData = (content, ranches) => {
    if (!content?.length || !ranches?.length) {
        return [];
    }

    return content.map(item => ({
        id: item.bhomeId || item.ranchId,
        data: prepareContent(item),
    }));
};

const prepareRanchContent = item => {
    const sortedContent = sortByDate(item);

    return sortedContent.map(contentItem => ({
        date: contentItem.date,
        beeFramesPerHive: contentItem.beeFramesPerHiveAverage || 0,
        honeyFramesPerHive: contentItem.honeyFramesPerHiveAverage || 0,
        broodFramesPerHive: contentItem.broodFramesPerHiveAverage || 0,
        beeFrameStandard: contentItem.quality || DEFAULT_QUALITY_OF_FRAMES,
    }));
};

export const prepareRanchContentData = content => {
    if (!content?.length) {
        return [];
    }

    return prepareRanchContent(content);
};

export const prepareBeekeepersColorsById = beekeepers =>
    beekeepers.reduce((result, item) => {
        result[item.id] = item.companyData?.color;

        return result;
    }, {});
